// import react
import React from "react"
import * as Ui from "components/ui"
import * as Sections from "components/sections"
import {BackupTypes} from "../sections";

export default function Content({keyRef, provider, service, contentBlocks, tags, learnTags, children}) {

  return(
    <div>
      <div className="mb-12">
        <h2 className="font-bold leading-snug" style={{fontSize: '2rem'}}>Overview</h2>
        <p className="mt-6 mb-6 text-gray-500 text-base">
          This integration connects your {provider} storage to SimpleBackups.<br/>
          You'll be able to select your {service} storage and use it to store your backups.<br/>
          <br/>
          <div className="">
              ✅ Store your backups on your own {service} account<br/>
              ✅ Configure your backups, your retention policy and store as many backups as you need.<br/>
              ✅ Own your backup files and have full control over them.
            <div className="flex items-center"><Ui.Image filename={`simplebackups-${keyRef}-storage.png`} alt={service} className="mt-12 md:w-1/2 m-auto"/></div>
          </div>
        </p>
      </div>

      <div className="mb-12">
        <h2 className="font-bold leading-snug" style={{fontSize: '2rem'}}>Store your servers, database, storage and applications backups on {service}</h2>
        <p className="mt-6 mb-6 text-gray-500 text-base">
        Once your storage is connected, you can use it to store any of your backups:
          <div className="pt-6">
            <BackupTypes/>
          </div>
        </p>
      </div>

      {contentBlocks && contentBlocks.map((block, index) => (
        <div key={index} className="mb-12">
          <h2 className="font-bold leading-snug" style={{fontSize: '2.0rem'}}>{block.title}</h2>
          <p className="mt-6 mb-6 text-gray-500 text-base" dangerouslySetInnerHTML={{__html: block.text}}>
          </p>
        </div>
      ))}

      {tags && tags.map((tag, index) => (
        <div key={index} className="border border-gray-lighter text-base rounded-xl">{tag.text}</div>
      ))}


      {learnTags && (
        <div className="mb-12">
          <h2 className="font-bold leading-snug" style={{fontSize: '2.0rem'}}>Guides</h2>
          <p className="mt-6 mb-6 text-gray-500 text-base">
            <Sections.LearnLinks tags={learnTags}/>
          </p>
        </div>
      )}
      {children}
    </div>
  )
}