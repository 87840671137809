import React from "react"
import Link from "gatsby-link"
import * as Ui from "components/ui"
import * as Layout from 'components/layout'
import { signupUrl } from 'utils/urlHelper'

const Page = ({
                key,
                logo,
                image,
                name,
                shortDescription,
                website,
                links,
                category,
                metaTitle,
                metaDescription,
                children}) => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      title={metaTitle}
      description={metaDescription}
    />
    <Layout.HeroEmpty/>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <div className="flex flex-col md:flex-row gap-4 md:gap-20">
            <div className="md:w-1/3">
              <div className="mb-6">
                <Link to="/catalog" className="text-base"><i className="fa fa-arrow-left text-xs text-gray-400 mr-2"></i>Browse Catalog</Link>
              </div>

              <div className="bg-gray-50 p-6 rounded-xl md:p-0  md:bg-white">
                <div className="flex md:flex-col gap-6 md:gap-2">
                  { (logo && <Ui.Image filename={logo} className="w-24 md:w-32 block"/>) }
                  <div className="flex items-center">
                    <Ui.Button size="small" color="primary" to={ signupUrl({sb_source: "website", sb_term: `integratio-${key}`}) }>Connect</Ui.Button>
                  </div>
                </div>

                <div className="md:mt-12 flex md:flex-col justify-between text-sm md:text-base">
                  <div className="md:border-b border-gray-100 md:pb-4 md:mb-4">
                    <p className="font-bold text-dark">Website</p>
                    <p><a href={website} className="text-primary">Learn more</a></p>
                  </div>

                  <div className="md:border-b border-gray-100 md:pb-4 md:mb-4">
                    <p className="font-bold text-dark">Links</p>
                    <p>
                      { links.map((link, index) => (<a href={link.url} className="text-primary">{link.text}</a>))}
                    </p>
                  </div>

                  <div className="md:border-b border-gray-100 md:pb-4 md:mb-4">
                    <p className="font-bold text-dark">Category</p>
                    <p className="text-primary">
                      Storage
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h1 className="font-bold mb-1 md:mt-12 leading-tight text-3.5xl md:text-4xl" >Store your backups on {name}</h1>
              <p className="text-gray-400">{shortDescription}</p>

              <div className="rounded-2xl overflow-hidden my-12">
              { image }
              { (!image && <Ui.Image filename='simplebackups-integration-default.png'/>) }
              </div>
              {children}
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

  </Layout.Layout>
)

export default Page
