import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';

const ContentPage = () => (
  <IntegrationTemplate
    key="aws"
    name="Amazon (AWS) S3"
    shortDescription="Save your backups on AWS S3 Storage."
    website={"https://aws.amazon.com/s3/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="aws-ico.png"
    metaTitle={meta.title.replace('##provider##', 'AWS S3 bucket')}
    metaDescription={meta.description.replace('##provider##', 'AWS S3 bucket')}
  >
    <IntegrationStorageContent
      keyRef="aws-s3"
      service="AWS S3"
      provider="AWS"
      learnTags={['aws-s3']}
    />
  </IntegrationTemplate>
)

export default ContentPage
